import AboutUs from './pages/AboutUs'
import GlobalStyle from './components/GlobaStyle'
import Nav from './components/Nav'
import Footer from './components/Footer'
import ContactUs from './pages/ContactUs'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import OurWork from './pages/OurWork'
import ProjectDetail from './pages/ProjectDetail'
import Process from './pages/Process'

import { Switch, Route, useLocation } from 'react-router-dom'

import { AnimatePresence } from 'framer-motion'

import ScrollTop from './components/ScrollTop'

function App() {
  const location = useLocation();
  // console.log("Test Location", location)
  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <AboutUs />
          </Route>
          <Route path="/work" exact>
            <OurWork />
          </Route>
          <Route path="/work/:id">
            <ProjectDetail />
          </Route>
          <Route path="/process" exact>
            <Process />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
        </Switch>
      </AnimatePresence>
      <Footer />
      <ScrollTop />
    </div>
  );
}

export default App;
