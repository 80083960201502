import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import webhosting from '../img/webhosting.svg'
import emailhosting from '../img/emailhosting.svg'
import domainnames from '../img/domainnames.svg'
import websitedesigndev from '../img/websitedesigndevwhite.svg'
import { motion } from 'framer-motion'
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from '../animation'
import { useScroll } from '../components/useScroll'

const OurWork = () => {
  const [element, controls] = useScroll()
  const [element2, controls2] = useScroll()
  const [element3, controls3] = useScroll()
  return (
    <WorkStyled
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <motion.div variants={sliderContainer}></motion.div>
      <Frame1 variants={slider}></Frame1>
      <Frame2 variants={slider}></Frame2>
      <Frame3 variants={slider}></Frame3>
      <Frame4 variants={slider}></Frame4>
      <ProjectStyled initial="show">
        <Link to="/work/webhosting">
          <HideOverflow>
            <motion.img
              variants={photoAnim}
              src={webhosting}
              alt="Website Hosting"
            />
          </HideOverflow>
        </Link>
        <motion.h2 variants={fade}>Web Hosting</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
      </ProjectStyled>
      <ProjectStyled
        ref={element}
        variants={fade}
        animate={controls}
        initial="hidden"
      >
        <Link to="/work/emailhosting">
          <HideOverflow>
            <img src={emailhosting} alt="Email Hosting" />
          </HideOverflow>
        </Link>
        <h2>Email Hosting</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
      </ProjectStyled>
      <ProjectStyled
        ref={element2}
        variants={fade}
        animate={controls2}
        initial="hidden"
      >
        <Link to="/work/domainnames">
          <HideOverflow>
            <img src={domainnames} alt="Domain Names" />
          </HideOverflow>
        </Link>
        <h2>Domain Name</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
      </ProjectStyled>
      <ProjectStyled
        ref={element3}
        variants={fade}
        animate={controls3}
        initial="hidden"
      >
        <Link to="/work/webdesigndev">
          <HideOverflow>
            <img src={websitedesigndev} alt="Servers" />
          </HideOverflow>
        </Link>
        <h2 className="smaller">Design & Development</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
      </ProjectStyled>
    </WorkStyled>
  )
}

const WorkStyled = styled(motion.div)`
  /* min-height: 100vh; */
  overflow: hidden;
  padding: 5rem 10rem;
  margin-top: 8em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h2,
  h3,
  p {
    color: var(--main-whiteish);
    padding: 1rem 0rem;
    font-size: 3rem;
  }
  @media (max-width: 1300px) {
    padding: 2rem 2rem;
  }
`

const ProjectStyled = styled(motion.div)`
  margin: 1rem 1rem;
  text-align: center;
  /* border: 1px solid #fff; */
  .line {
    height: 0.5rem;
    background: #009fb7;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    max-width: 20rem;
    height: 30vh;
    object-fit: cover;
    @media (max-width: 1300px) {
      object-fit: initial;
    }
  }
`

const HideOverflow = styled.div`
  overflow: hidden;
`

// Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  /* height: 100vh; */
  background: var(--main-yellowish);
  z-index: 2;
`

const Frame2 = styled(Frame1)`
  background: var(--main-grayish);
`
const Frame3 = styled(Frame1)`
  background: var(--main-blackish);
`
const Frame4 = styled(Frame1)`
  background: var(--main-lightgrayish);
`

export default OurWork
