import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledComponentLayout = styled(motion.div)`
    min-height:90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    color: var(--main-whiteish);
    @media (max-width: 1300px) {
        display:block;
        padding:2rem 2rem;
        text-align:center;
    }
`;

export const StyledComponentDescription = styled.div`
flex:1;
padding-right:5rem;
z-index:2;
h2 {
    font-weight:lighter;
}
@media (max-width: 1300px) {
        padding:0;
        button {
            margin: 1rem 2rem 4rem;
        }
    }
`

export const StyledComponentImg = styled.div`
flex:1;
overflow:hidden;
z-index:2;
img {
width:100%;
height:auto;
object-fit:cover;
@media only screen and (max-width: 600px) {
        object-fit:contain;
}
}
`

export const StyledComponentHide = styled.div`
overflow:hidden;
h2 {
    font-size: 2.5rem;
}
`

export const ProcessStyledOrderItems = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    color:var(--main-whiteish);
    min-height:90vh;
    @media (max-width: 1300px) {
        padding:2rem 2rem;
        flex-direction:column-reverse;
        text-align:center;
    }
`