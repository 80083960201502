import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'

const Nav = () => {
    const { pathname } = useLocation();
    return (
        <StyledNav>
            <Link to="/">
                <h1>
                    CONSULI<span>TATE</span>
                </h1>
            </Link>
            <ul>
                <li>
                    <Link to="/">About Us</Link>
                    <Line
                        transition={{ duration: 0.75 }}
                        initial={{ width: "0%" }}
                        animate={{ width: pathname === "/" ? "50%" : "0%" }}
                    />
                </li>
                <li>
                    <Link to="/work">Solutions</Link>
                    <Line
                        transition={{ duration: 0.75 }}
                        initial={{ width: "0%" }}
                        animate={{ width: pathname === "/work" ? "50%" : "0%" }}
                    />
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                    <Line
                        transition={{ duration: 0.75 }}
                        initial={{ width: "0%" }}
                        animate={{ width: pathname === "/contact" ? "50%" : "0%" }}
                    />
                </li>
            </ul>
        </StyledNav >
    )
}

const StyledNav = styled.nav`
background-color:#3b3b3b;
min-height:10vh;
display:flex;
margin: auto;
justify-content: space-between;
align-items:center;
padding: 1rem 10rem;
position:sticky;
top:0;
z-index:10;
a {
    color:#FFF;
    text-decoration:none;
}
h1 {
    font-size:3rem;
    span {
        font-style:italic;
    }
}
ul {
    display:flex;
    list-style:none;
}
li {
    padding-left:10rem;
    position: relative;
}
span {
    font-size:2.9rem;
    font-weight:lighter;
}
@media (max-width: 1300px) {
    flex-direction:column;
    padding:2.5rem 0;
    ul {
        padding:2rem 0;
        justify-content:space-around;
        width:100%;
        li {
            padding:0;
        }
    }
}
`

const Line = styled(motion.div)`
height: 0.3rem;
background: var(--main-blueish);
width:0%;
position: absolute;
bottom: -80%;
left:60%;
@media (max-width: 1300px) {
    left:0%;
}
`

export default Nav
