import { createGlobalStyle } from 'styled-components'

// Colour Palette: https://coolors.co/272727-fed766-009fb7-696773-eff1f3

const GlobalStyle = createGlobalStyle`

:root {
    --main-whiteish: #FFFFFF;
    --main-blackish: #272727;
    --main-blueish: #009fb7;
    --main-yellowish: #fed766;
    --main-grayish: #696773;
    --main-lightgrayish: #eff1f3;
}

* {
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html {
    @media (max-width: 1700px) {
        font-size:75%;
    }
}

body {
    background: #272727;
    font-family: 'Roboto', sans-serif;
    overflow-x:hidden;
}

button {
    font-weight:bold;
    font-size:1.1rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 3px solid #eff1f3;
    background: transparent;
    color: #FFF;
    transition: all 0.5s ease;
    font-family: 'Roboto', sans-serif;
    &:hover {
        background-color: var(--main-blueish);
        color:#272727;
        border: 3px solid var(--main-blueish);
        }
    &:active, :focus {
        border: 3px solid var(--main-yellowish);
        background-color: var(--main-yellowish);
        color:#272727;
    }
    a {
        text-decoration:none;
        color: #FFF;
        transition: all 0.5s ease;
        &:hover {
            color:#272727;
        }
    }
}

h2 {
    font-weight:lighter;
    font-size: 4rem;
}

h3 {
    color:#FFF;
}

h4 {
    font-weight:bold;
    font-size:2.5rem;
}

a {
    font-size:1.5rem;
}

span {
    font-weight:bold;
    color:#009fb7;
}

p {
    padding: 3rem 0rem;
    color:#CCC;
    font-size:1.4rem;
    line-height:150%;
}

`

export default GlobalStyle
