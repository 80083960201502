import React from 'react'
import { StyledComponentLayout } from '../styles'
import styled from 'styled-components'
import Toggle from './Toggle'
import { AnimateSharedLayout } from 'framer-motion'

import { useScroll } from './useScroll'
import { scrollReveal } from '../animation'

const FaqSection = ({}) => {
  const [element, controls] = useScroll()
  return (
    <Faq
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>
        Any Questions?<span>FAQ</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="How do I start?">
          <div className="answer">
            <p>
              This all depends on your requirements but we have a{' '}
              <a href="/process">general process flow</a>.
            </p>
            <p>
              You are always free to reach out on{' '}
              <a href="mailto:support@consulitate.com">
                support@consulitate.com
              </a>
            </p>
          </div>
        </Toggle>
        <Toggle title="How can I reach support?">
          <div className="answer">
            <p>
              Easy! Just send email to{' '}
              <a href="mailto:support@consulitate.com">
                support@consulitate.com
              </a>{' '}
              and we will get back to you in 24 hours.
            </p>
          </div>
        </Toggle>
        <Toggle title="How do I request statement?">
          <div className="answer">
            <p>
              Please request this by sending email to{' '}
              <a href="mailto:billing@consulitate.com">
                billing@consulitate.com
              </a>
            </p>
          </div>
        </Toggle>
        <Toggle title="How do I upgrade, renew or cancel my domain?">
          <div className="answer">
            <p>
              Please send email to{' '}
              <a href="mailto:support@consulitate.com">
                support@consulitate.com
              </a>
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  )
}

const Faq = styled(StyledComponentLayout)`
  display: block;
  a {
    color: #fff;
  }
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`

export default FaqSection
