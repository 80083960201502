import React from 'react'
import { Link } from 'react-router-dom'
import home1 from '../img/main-think.svg'
import { StyledComponentLayout, StyledComponentDescription, StyledComponentImg, StyledComponentHide } from '../styles'
import { motion } from 'framer-motion'
import { titleAnim, fade, photoAnim } from '../animation'
import Wave from './Wave'

const AboutSection = () => {

    const wordsAnimateSlow = {
        hidden: { opacity: 0 },
        show: { opacity: 1, transition: { duration: 4.5 } }
    }
    const wordsAnimateMedium = {
        hidden: { opacity: 0 },
        show: { opacity: 1, transition: { duration: 3.5 } }
    }
    const wordsAnimateFast = {
        hidden: { opacity: 0 },
        show: { opacity: 1, transition: { duration: 2 } }
    }
    // const containerAnimate = {
    //     hidden: { x: 100 },
    //     show: { x: 0, transition: { duration: 0.75, staggerChildren: 0.75, when: "beforeChildren" } }, // when: "beforeChildren" or "afterChildren" works for this
    // }

    return (
        <StyledComponentLayout>
            <StyledComponentDescription>
                <motion.div>
                    <StyledComponentHide>
                        <motion.h2 variants={titleAnim}>
                            <motion.span variants={wordsAnimateFast}
                            >Consult</motion.span> your requirements</motion.h2>
                    </StyledComponentHide>
                    <StyledComponentHide>
                        <motion.h2 variants={titleAnim}>
                            <motion.span variants={wordsAnimateMedium}
                            >Facilitate</motion.span> your requests</motion.h2>
                    </StyledComponentHide>
                    <StyledComponentHide>
                        <motion.h2 variants={titleAnim}>
                            <motion.span variants={wordsAnimateSlow}
                            >Listen</motion.span> to your needs</motion.h2>
                    </StyledComponentHide>
                </motion.div>
                <motion.p variants={fade}>Consult and facilitate your needs to get your brand online. From domain registration, hosting, website development & support.</motion.p>
                <Link to="/work">
                    <motion.button variants={fade}>Learn More</motion.button>
                </Link>
            </StyledComponentDescription>
            <StyledComponentImg>
                <motion.img variants={photoAnim} src={home1} alt="Home Image" />
            </StyledComponentImg>
            <Wave />
        </StyledComponentLayout>
    )
}



export default AboutSection