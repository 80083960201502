import React from 'react'
import ProcessSection from '../components/ProcessSection'
import { motion } from 'framer-motion'
import { pageAnimation } from '../animation'


const Process = () => {
    return (
        <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <ProcessSection />
        </motion.div>
    )
}

export default Process;
