//Import Images
import webhosting from './img/webhosting.svg'
import webhosting2 from './img/webhosting.svg'
import emailhosting from './img/emailhosting.svg'
import emailhosting2 from './img/emailhosting.svg'
import domainnames from './img/domainnames.svg'
import domainnames2 from './img/domainnames.svg'
import websitedesigndevwhite from './img/websitedesigndevwhite.svg'
import websitedesigndevwhite2 from './img/websitedesigndevwhite.svg'

export const ProjectState = () => {
  return [
    {
      title: 'Web Hosting',
      mainImg: webhosting,
      secondaryImg: webhosting2,
      url: '/work/webhosting',
      info: 'We offer a range of services, each customised according to your needs. Below you can see our bundles packages. If you need anything more or additional please reach out to us. All packages are renewed annually.',
      moreDetails: [
        {
          iconImg: '',
          title: 'DNS only',
          description: `If you have existing mail or web records then we can manage the DNS for you.`,
          bulletpoint1: '1 DNS zone',
          bulletpoint2: '50 DNS records',
          bulletpoint3: 'Dynamic DNS',
          bulletpoint4: 'Web redirects',
          bulletpoint5: 'Support included',
          price: '12',
        },
        {
          iconImg: '',
          title: 'Starter',
          description:
            'Basic package to get you started. Ideal if you just starting up. ',
          bulletpoint1: '1GB storage email/web',
          bulletpoint2: '30min support included',
          bulletpoint3: 'Transfer included',
          bulletpoint4: '5 email boxes, 200MB space per email, POP3',
          bulletpoint5: 'Free CMS installation',
          price: '35',
        },
        {
          title: 'Medium',
          description: 'Upgraded package to support your small business.',
          bulletpoint1: '5GB storage email/web',
          bulletpoint2: 'Free domain',
          bulletpoint3: '45min support included',
          bulletpoint4: '10 email boxes, 500MB space per mailbox',
          bulletpoint5: 'POP3/IMAP',
          price: '60',
        },
        {
          title: 'Full',
          description: 'The full package for all your business needs.',
          bulletpoint1: 'Free domain',
          bulletpoint2: 'Support included',
          bulletpoint3: 'Google Apps Upgrade Option',
          bulletpoint4: '15 email boxes, 1GB space per mailbox',
          bulletpoint5: 'Hosting management console',
          price: '150',
        },
      ],
    },
    {
      title: 'Email Hosting',
      mainImg: emailhosting,
      url: '/work/emailhosting',
      secondaryImg: emailhosting2,
      info: 'We offer a range of services, each customised according to your needs. Below you can see our bundles packages. If you need anything more or additional please reach out to us. All prices are per user per month. This will be invoiced on annual basis.',
      moreDetails: [
        {
          title: 'Starter',
          description: 'Best package to get you up and running',
          bulletpoint1: '250MB storage space',
          bulletpoint2: 'POP3',
          bulletpoint3: 'Webmail',
          bulletpoint4: '30min support',
          bulletpoint5: '5 email forwards',
          price: '1',
        },
        {
          title: 'Medium',
          description: 'Ideal for small business',
          bulletpoint1: '1GB storage space',
          bulletpoint2: 'POP3/IMAP',
          bulletpoint3: 'Webmail',
          bulletpoint4: '45min support',
          bulletpoint5: '10 email forwards',
          price: '2',
        },
        {
          title: 'Full',
          description: 'Full package supporting Google Apps',
          bulletpoint1: 'Storage on request',
          bulletpoint2: 'POP3/IMAP',
          bulletpoint3: 'Webmail',
          bulletpoint4: 'Free support',
          bulletpoint5: 'Unlimited email forwards',
          price: '10',
        },
      ],
    },
    {
      title: 'Domain Names',
      mainImg: domainnames,
      url: '/work/domainnames',
      secondaryImg: domainnames2,
      info: 'We support all top level domains. Reach out to us if you looking for specific name for your online presence. These are renewed annually.',
      moreDetails: [
        {
          title: 'Registration',
          description:
            'We will help you registering & setting up your domain name.',
          bulletpoint1: 'Free registration support',
          bulletpoint2: 'DNS records configure',
          bulletpoint3: 'Redirect included',
          bulletpoint4: 'Free landing page',
          bulletpoint5: 'Unlimited email forwards',
          price: 'On request',
        },
        {
          title: 'Transfer',
          description:
            'Have domain with existing registrar, no worries, we will assist you transfering the domain to us.',
          bulletpoint1: 'Free transfer support',
          bulletpoint2: 'DNS records configure',
          bulletpoint3: 'Redirect included',
          bulletpoint4: 'Free landing page',
          bulletpoint5: 'Unlimited email forwards',
          price: '2',
        },
      ],
    },
    {
      title: 'Design & Development',
      mainImg: websitedesigndevwhite,
      url: '/work/webdesigndev',
      secondaryImg: websitedesigndevwhite2,
      info: 'We offer a range of web design/development solutions. Below are the basic packages to get started. We can always customise these packages according to your needs. These are once of costs excluding domain & hosting fees. ',
      moreDetails: [
        {
          iconImg: '',
          title: 'Starter',
          description:
            'Basic package to get you started. Ideal if you just starting up. ',
          bulletpoint1: '5 page website',
          bulletpoint2: 'Contact form',
          bulletpoint3: 'Blog',
          bulletpoint4: 'SEO',
          bulletpoint5: 'CMS upgrade option available',
          price: '250',
        },
        {
          iconImg: '',
          title: 'Medium',
          description:
            'Small business package to expand content & offering online. ',
          bulletpoint1: '10 page website',
          bulletpoint2: 'Contact form',
          bulletpoint3: 'Blog',
          bulletpoint4: 'SEO',
          bulletpoint5: 'CMS included, eCommerce upgrade option available',
          price: '600',
        },
        {
          iconImg: '',
          title: 'Full',
          description: 'Full package to get your brand & shop online ',
          bulletpoint1: '12 page website',
          bulletpoint2: 'Contact form',
          bulletpoint3: 'Blog',
          bulletpoint4: 'SEO',
          bulletpoint5: 'CMS & eCommerce included',
          price: '1800',
        },
      ],
    },
  ]
}

export default ProjectState
