import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ProjectState } from '../projectState'

import { motion } from 'framer-motion'
import { pageAnimation } from '../animation'

export const ProjectDetail = () => {
  const history = useHistory()
  const url = history.location.pathname
  console.log('history data check', history)

  const [projects, setProjects] = useState(ProjectState)
  const [project, setProject] = useState(null)

  useEffect(() => {
    const currentProject = projects.filter(
      (stateProject) => stateProject.url === url,
    )
    setProject(currentProject[0])
  }, [projects, url])
  return (
    <>
      {project && (
        <DetailsStyled
          variants={pageAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <HeadlineStyled>
            <h2>{project.title}</h2>
            <img src={project.mainImg} alt={project.title} />
          </HeadlineStyled>
          <InfoBar>{project.info}</InfoBar>
          <MoreDetailsStyled>
            {project.moreDetails.map((moreDetail) => (
              <MoreDetail
                title={moreDetail.title}
                description={moreDetail.description}
                description2={moreDetail.description2}
                bulletpoint1={moreDetail.bulletpoint1}
                bulletpoint2={moreDetail.bulletpoint2}
                bulletpoint3={moreDetail.bulletpoint3}
                bulletpoint4={moreDetail.bulletpoint4}
                bulletpoint5={moreDetail.bulletpoint5}
                price={moreDetail.price}
                key={moreDetail.title}
              />
            ))}
          </MoreDetailsStyled>
          {/* <ImageDisplayStyled>
                        <img src={project.secondaryImg} alt="project" />
                    </ImageDisplayStyled> */}
        </DetailsStyled>
      )}
    </>
  )
}

const DetailsStyled = styled(motion.div)`
  color: #fff;
`

const HeadlineStyled = styled.div`
  min-height: 90vh;
  padding-top: 20vh;
  position: relative;
  h2 {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    @media (max-width: 1300px) {
      object-fit: initial;
    }
  }
`

const MoreDetailsStyled = styled.div`
  min-height: 80vh;
  display: flex;
  margin: 5rem 10rem;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1300px) {
    display: block;
    margin: 2rem 2rem;
  }
`

const InfoBar = styled.div`
  margin: 5rem 0rem;
  display: flex;
  font-size: 1.5em;
  margin: 5rem 10rem;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1300px) {
    display: block;
    margin: 5rem 2rem;
  }
`

const MoreDetailsExtraStyle = styled.div`
  margin: 5rem 0rem;
  display: flex;
  padding: 5rem;
  flex-direction: column;
  flex-wrap: wrap;
  width: 35em;
  h3 {
    font-size: 2rem;
  }
  .line {
    width: 80%;
    background: var(--main-blueish);
    height: 0.5rem;
    margin: 1rem 0rem;
  }
  ul {
    margin-left: 1.5em;
    li {
      color: #ccc;
      font-size: 1.3em;
    }
  }
  p {
    padding: 2rem 0rem;
  }
  .price {
    font-size: 2em;
    border: 1px #fff solid;
    text-align: center;
    margin: 1em;
    width: 65%;
    padding: 1em;
    .currency {
    }
  }
  @media (max-width: 1300px) {
    padding: 0rem;
    .price {
      width: 40%;
    }
    display: block;
    margin: 5rem 0rem;
  }
`

const ImageDisplayStyled = styled.div`
  min-height: 50vh;
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`

const MoreDetail = ({
  title,
  description,
  bulletpoint1,
  bulletpoint2,
  bulletpoint3,
  bulletpoint4,
  bulletpoint5,
  price,
}) => {
  return (
    <MoreDetailsExtraStyle>
      <h3>{title}</h3>
      <div className="line"></div>
      <p>{description}</p>
      <ul>
        <li>{bulletpoint1}</li>
        <li>{bulletpoint2}</li>
        <li>{bulletpoint3}</li>
        <li>{bulletpoint4}</li>
        <li>{bulletpoint5}</li>
      </ul>
      <p className="price">
        <span className="currency">€ </span>
        {price}
      </p>
    </MoreDetailsExtraStyle>
  )
}

export default ProjectDetail
