import React from 'react'
import discuss from '../img/process-discuss.svg'
import collection from '../img/process-collection.svg'
import feedback from '../img/process-feedback.svg'
import launch from '../img/process-launch.svg'
import {
  StyledComponentLayout,
  StyledComponentDescription,
  StyledComponentImg,
  ProcessStyledOrderItems,
} from '../styles'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { titleAnim, fade, photoAnim } from '../animation'
// import Wave from './Wave'

const ProcessSection = () => {
  const wordsAnimateSlow = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 4.5 } },
  }
  const wordsAnimateMedium = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 3.5 } },
  }
  const wordsAnimateFast = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 2 } },
  }

  return (
    <>
      <StyledComponentLayout>
        <StyledComponentDescription>
          <motion.div>
            <motion.h2 variants={titleAnim}>
              1.{' '}
              <motion.span variants={wordsAnimateFast}>Discussion</motion.span>
            </motion.h2>
          </motion.div>
          <motion.p variants={fade}>
            Let's get to know each other. Introduction video call or chat to
            discuss your needs and what you want to achieve.
          </motion.p>
          <motion.p variants={fade}></motion.p>
          {/* <motion.button variants={fade}>Learn More</motion.button> */}
        </StyledComponentDescription>
        <StyledComponentImg>
          <motion.img variants={photoAnim} src={discuss} alt="Discussion" />
        </StyledComponentImg>
      </StyledComponentLayout>
      <ProcessStyledOrderItems>
        <StyledComponentImg>
          <motion.img variants={photoAnim} src={collection} alt="Collection" />
        </StyledComponentImg>
        <StyledComponentDescription>
          <motion.div>
            <motion.h2 variants={titleAnim}>
              2.{' '}
              <motion.span variants={wordsAnimateFast}>Collection</motion.span>
            </motion.h2>
          </motion.div>
          <motion.p variants={fade}>
            We will collect the information as needed. We will share the
            timeframe and estimated project completion date once all received.
          </motion.p>
          {/* <motion.button variants={fade}>Learn More</motion.button> */}
        </StyledComponentDescription>
      </ProcessStyledOrderItems>
      <StyledComponentLayout>
        <StyledComponentDescription>
          <motion.div>
            <motion.h2 variants={titleAnim}>
              3. <motion.span variants={wordsAnimateFast}>Feedback</motion.span>{' '}
              Session
            </motion.h2>
          </motion.div>
          <motion.p variants={fade}>
            This is the exciting part where you can review the work that was
            done and send us feedback. We can either do this on video call or
            chat.
          </motion.p>
          {/* <motion.button variants={fade}>Learn More</motion.button> */}
        </StyledComponentDescription>
        <StyledComponentImg>
          <motion.img variants={photoAnim} src={feedback} alt="Feedback" />
        </StyledComponentImg>
      </StyledComponentLayout>
      <ProcessStyledOrderItems>
        <StyledComponentImg>
          <motion.img variants={photoAnim} src={launch} alt="Launch" />
        </StyledComponentImg>
        <StyledComponentDescription>
          <motion.div>
            <motion.h2 variants={titleAnim}>
              4. <motion.span variants={wordsAnimateSlow}>Launch</motion.span>
            </motion.h2>
          </motion.div>
          <motion.p variants={fade}>
            Feedback session input used and last preparations done for launch.
          </motion.p>
        </StyledComponentDescription>
      </ProcessStyledOrderItems>
      <StyledComponentLayout>
        <StyledComponentDescription>
          <FullWidthStyled>
            <motion.h2 variants={titleAnim}>
              5.{' '}
              <motion.span variants={wordsAnimateFast}>
                Ready to get started?
              </motion.span>
              <motion.p variants={fade}>
                Let's start the discussion. Send us email with your requirements
                and will take it from there.
              </motion.p>
            </motion.h2>
            <motion.button variants={fade}>
              <a href="mailto:support@consulitate.com">Let's talk!</a>
            </motion.button>
          </FullWidthStyled>
        </StyledComponentDescription>
      </StyledComponentLayout>
    </>
  )
}

const FullWidthStyled = styled.div`
  text-align: center;
`

export default ProcessSection
