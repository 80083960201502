import React from 'react'
import { Link } from 'react-router-dom'
import domain from '../img/domain.svg'
import email from '../img/email.svg'
import hosting from '../img/hosting.svg'
import website from '../img/website.svg'
import home2 from '../img/main-services.svg'
import { StyledComponentLayout, StyledComponentDescription, StyledComponentImg, StyledComponentHide } from '../styles'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { scrollReveal, fade } from '../animation'
import { useScroll } from './useScroll'

const ServicesSection = () => {
    const [element, controls] = useScroll()
    return (
        <StyledComponentServices animate={controls} variants={scrollReveal} initial="show" ref={element}>
            <StyledComponentDescription>
                <h2><span>Services</span></h2>
                <Cards>
                    <Card>
                        <div className="icon">
                            <img src={hosting} alt="clock" />
                            <h3>Web Hosting</h3>
                        </div>
                        <p>From starter to full package options. The package is adjusted according to the client's requirements.</p>
                    </Card>
                    <Card>
                        <div className="icon">
                            <img src={email} alt="clock" />
                            <h3>Email Hosting</h3>
                        </div>
                        <p>Add email to your existing domain or stand alone email hosting. We have solution, reach out to us. </p>
                    </Card>
                    <Card>
                        <div className="icon">
                            <img src={domain} alt="clock" />
                            <h3>Domains</h3>
                        </div>
                        <p>We support most top level domains. We will assist you in finding name that suits your needs.</p>
                    </Card>
                    <Card>
                        <div className="icon">
                            <img src={website} alt="clock" />
                            <h3>Websites</h3>
                        </div>
                        <p>Over 15 years experience on web solutions. We will take you from step 1 to building your online presence.</p>
                    </Card>
                </Cards>
                <Link to="/process">
                    <motion.button variants={fade}>
                        Let's get started!
                    </motion.button>
                </Link>
            </StyledComponentDescription>
            <StyledComponentImg>
                <img src={home2} alt="" srcSet="" />
            </StyledComponentImg>
        </StyledComponentServices>
    )
}

const StyledComponentServices = styled(StyledComponentLayout)`
a {
    color:var(--main-whiteish);
    text-decoration:none;
    &:hover {
        color:var(--main-blackish);
    }
}
h2 {
    padding-bottom:5rem;
}
p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
}
@media (max-width: 1300px) {
   p {
       width:100%;

   }
}
`

const Cards = styled.div`
display:flex;
flex-wrap:wrap;
@media (max-width: 1300px) {
    justify-content:center;
}
`

const Card = styled.div`
flex-basis:20rem;
.icon {
    display:flex;
    align-items:center;
    h3 {
        margin-left: 1rem;
        background: var(--main-whiteish);
        color: var(--main-blackish);
        padding: 1rem;
    }
}
@media (max-width: 1300px) {
    justify-content:center;
    .icon {
        justify-content:center;
    }
}
`

export default ServicesSection
