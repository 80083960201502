import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import mastercard from '../img/mastercard.svg'
import visa from '../img/visa.svg'
import paypal from '../img/paypal.svg'

const Nav = () => {
  return (
    <StyledNav>
      <div>
        <span>
          Copyright &copy; Consulitate 2023 |
          <span>
            <Link to="/terms"> Terms & Conditions</Link>
          </span>{' '}
          |
          <span>
            <Link to="/privacy"> Privacy Policy</Link>
          </span>
        </span>
      </div>
      <div>
        <span className="cards">
          <span>
            <img class="icon" src={mastercard} alt="Master Card" />
          </span>
          <span>
            <img class="icon" src={visa} alt="Visa" />
          </span>
          <span>
            <img class="icon" src={paypal} alt="Paypal" />
          </span>
        </span>
      </div>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  background-color: #3b3b3b;
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  position: sticky;
  top: 0;
  z-index: 10;
  .icon {
    width: 4em;
    height: 2em;
    margin: 1em 0.5em;
  }
  .cards {
    display: flex;
    align-content: center;
    align-items: center;
  }
  a {
    font-size: 0.9em;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  span {
    color: var(--main-whiteish);
    font-size: 0.9rem;
    font-weight: bold;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 2.5rem 0;
    ul {
      padding: 2rem 0;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }
`

export default Nav
